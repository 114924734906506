import clsx from 'clsx'

const Logo = ({ className = undefined }) => {
  return (
    <img
      src="/img/only-subasta-logo-64x64.png"
      alt="Only Subasta logo"
      className={clsx('logo', 'w-16 h-16', className)}
    />
  )
}

export default Logo
