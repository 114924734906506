import axios from 'axios'

axios.defaults.withCredentials = true

// Intercept axios 401 responses
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      window.location = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)

window.axios = axios

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */
import './echo'
