import { FaWhatsapp } from 'react-icons/fa'
import config from '@/Lib/config'

// Whatsapp floating button component using fabicons
const FloatingWhatsApp = () => {
  return (
    <div className="fixed right-0 bottom-0 mr-4 mb-4">
      <a
        href={config.whatsappUrl}
        className="block rounded-full bg-green-500 px-2 py-2 font-bold text-white shadow-lg hover:bg-green-600"
        target="_blank"
        rel="noopener noreferrer"
        title="WhatsApp"
      >
        <FaWhatsapp className="h-8 w-8" />
      </a>
    </div>
  )
}

export default FloatingWhatsApp
